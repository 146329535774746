
import Vue from 'vue'
import HeroImageDynamicHeight from '~/components/images/HeroImageDynamicHeight.vue'
import FooterComponent from '~/components/navigations/myHausHeld/Footer.vue'
import HowItWorks from '~/components/sections/HowItWorks.vue'
import MyHausHeldDataSecurityTeaser from '~/components/sections/MyHausHeldDataSecurityTeaser.vue'
import MyHausHeldFeatures from '~/components/sections/MyHausHeldFeatures.vue'
import MyHausHeldSponsorSlider from '~/components/sections/MyHausHeldSponsorSlider.vue'
import MyHausHeldTryIt from '~/components/sections/MyHausHeldTryIt.vue'
import { generateHead } from '~/core/config/head.config'

export default Vue.extend({
  name: 'LandingPage',
  components: {
    HeroImageDynamicHeight,
    FooterComponent,
    MyHausHeldFeatures,
    HowItWorks,
    MyHausHeldDataSecurityTeaser,
    MyHausHeldSponsorSlider,
    MyHausHeldTryIt,
  },
  layout: 'landing',
  auth: false,
  /* istanbul ignore next */
  head() {
    const head = generateHead('mhLanding')
    head.bodyAttrs.class = 'public-area-theme mh'
    return { ...head }
  },
})
