
import Vue from 'vue'
import { capitalizeFirstLetterOnly } from '~/core/functions/capitalizeFirstLetterOnly'

export default Vue.extend({
  data() {
    return {
      active: 'minergie',
      interval: null,
      isRunning: true,
    }
  },
  mounted() {
    this.automatedSponsor()
    this.$data.interval = setInterval(this.automatedSponsor, 15000)
  },
  methods: {
    toggleSponsor(sponsor: string) {
      clearInterval(this.$data.interval)
      this.$data.isRunning = false
      this.$data.active = sponsor
    },
    automatedSponsor() {
      const sponsors = ['minergie', 'swissLife', 'wir']
      let delay = 0
      for (const sponsor of sponsors) {
        setTimeout(() => {
          if (this.$data.isRunning === true) {
            this.$data.active = sponsor
          }
        }, 0 + delay)
        delay += 5000
      }
    },
    capitalizeFirstLetterOnly,
  },
})
