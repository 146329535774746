
import Vue from 'vue'
export default Vue.extend({
  name: 'HeroImageDynamicHeight',
  props: {
    imageClass: {
      type: String,
      required: true,
    },
    heightSetting: {
      type: String,
      default: '',
      required: false,
    },
    textClass: {
      type: String,
      default: '',
      required: false,
    },
  },
})
